<template>
    <div :class="classObj" class="app-wrapper">
        <div v-if="device==='mobile'&&sidebar.opened" class="drawer-bg" @click="handleClickOutside"/>
<!--        <sidebar class="sidebar-container"/>-->
        <div>
            <div :class="{'fixed-header':fixedHeader}">
                <navbar/>
            </div>
<!--            <app-main/>-->
            <!-- 页面左侧二级菜单栏，和主体内容区域部分 -->
            <section class="router-main">
                <router-view></router-view>
            </section>
            <right-panel v-if="showSettings">
                <settings/>
            </right-panel>
        </div>
    </div>
</template>

<script>
    import RightPanel from '@/components/RightPanel'
    import { Navbar, Settings } from './components'
    import ResizeMixin from './mixin/ResizeHandler'
    import { mapState } from 'vuex'
    export default {
        name: 'Layout',
        components: {
            Navbar,
            RightPanel,
            Settings,
        },
        mixins: [ResizeMixin],
        data() {
            return {
                // 上一次点击时间
                lastTime: null,
                // 当前时间
                currentTime: null,
                // 超时时间, 如果 10 分钟 都没有点击页面就算超时
                sys_timeout: 10 * 60 * 1000,
                // 检查的时间，每隔2分钟检查一次有没有超时
                check_time: 2 * 60 * 1000,
                // 计时器
                whole_timer: null
            }
        },
        computed: {
            ...mapState({
                sidebar: state => state.app.sidebar,
                device: state => state.app.device,
                showSettings: state => state.settings.showSettings,
                needTagsView: state => state.settings.tagsView,
                fixedHeader: state => state.settings.fixedHeader
            }),
            classObj() {
                return {
                    hideSidebar: !this.sidebar.opened,
                    openSidebar: this.sidebar.opened,
                    withoutAnimation: this.sidebar.withoutAnimation,
                    mobile: this.device === 'mobile'
                }
            }
        },
        created() {
            // 启动这个定时器
            // this.isLoginOut();
        },
        methods: {
            handleClickOutside() {
                this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
            },
            // 判断是否超时
            isTimeOut() {
                // 页面上一次的点击时间
                this.lastTime = this.$store.state.login.lastTime;
                this.currentTime = new Date().getTime();
                // 超时了
                if ((this.currentTime - this.lastTime) > this.sys_timeout) {
                    return true;
                } else {
                    return false;
                }
            },
            isLoginOut() {
                // console.log("11111:" + this)
                // 每次用定时器之前先清除一下
                clearInterval(this.whole_timer);
                // 这里要备份一下this指针
                let _this = this;
                this.whole_timer = setInterval(function () {
                    console.log(_this.isTimeOut())
                    // 判断一下是否超时，如果超时了就退出
                    if (_this.isTimeOut()) {
                        // console.log("22222:" + this)
                        // _this.$store.dispatch('user/logout');
                        _this.$router.push(`/`);
                        // 如果退出了就清除这个定时器，这里要延迟一秒清除，因为跳转到登录界面会有一点点延迟~
                        setTimeout(function () {
                            clearInterval(_this.whole_timer);
                        }, 1000);
                    }
                }, _this.check_time);
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../styles/mixin.scss";
    @import "../styles/variables.scss";
    .app-wrapper {
        @include clearfix;
        position: relative;
        height: 100%;
        width: 100%;
        &.mobile.openSidebar {
            position: fixed;
            top: 0;
        }
    }

    .drawer-bg {
        background: #000;
        opacity: 0.3;
        width: 100%;
        top: 0;
        height: 100%;
        position: absolute;
        z-index: 999;
    }

    .fixed-header {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 9;
        //width: calc(100% - #{$sideBarWidth});
        width: 100%;
        transition: width 0.28s;
    }

    .hideSidebar .fixed-header {
        //width: calc(100% - 60px)
    }

    .mobile .fixed-header {
        width: 100%;
    }
    .router-main {
        /* 50= navbar  50  */
        min-height: calc(100vh - 52px);
        // width: 100%;
        min-width: 1480px;
        position: relative;
        // overflow: hidden;
        overflow: auto;
    }
    .fixed-header + .router-main {
        padding-top: 50px;
    }
</style>
