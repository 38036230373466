import Vue from 'vue'
import VueRouter from 'vue-router'
import appSession from '@js/session.sg'
/* Layout */
import Layout from '@/layout'
import Main from '@/layout/MainContent'
/* Router Modules */
// import commonRouter from './modules/common'
Vue.use(VueRouter)
/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'/'el-icon-x' the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
let routes = [
    {
        path: '/',
        name: 'login',
        meta: {
            title: '登录',
            hideInMenu: true
        },
        component: () => import(/* webpackChunkName: "main" */ '../views/Login.vue')
    },
    {
        path: '/ebook/list',
        name: 'ebooklist',
        meta: {
            title: '操作手册',
            hideInMenu: true
        },
        component: () => import(/* webpackChunkName: "main" */ '../views/system/ebook/EbookHome.vue')
    },
    {
        path: '/ebook/detail',
        name: 'ebookdetail',
        meta: {
            title: '操作手册',
            hideInMenu: true
        },
        component: () => import(/* webpackChunkName: "main" */ '../views/system/ebook/EbookDetail.vue')
    },
    {
        path: '/',
        component: Layout,
        meta: {
            title: '整体页面布局'
        },
        children: [
            {
                path: '/dashboard',
                component: () => import('../views/dashboard/index'),
                name: '首页',
                meta: { title: '首页', icon: 'dashboard', affix: true }
            },
            {
                path: '/app',
                component: Main,
                meta: {
                    title: '左侧菜单布局'
                },
                children: [
                    {
                        path: 'dashboard',
                        component: () => import('../views/dashboard/index'),
                        name: '首页',
                        meta: { title: '首页', icon: 'dashboard', affix: true }
                    },
                    //学员管理
                    {
                        path: 'user/userlist',
                        name: 'userlist',
                        meta: { title: '注册用户', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "user" */ '../views/user/UserQuery.vue')
                    },
                    {
                        path: 'user/userEnroll',
                        name: 'userEnroll',
                        meta: { title: '报名管理', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "user" */ '../views/user/UserEnrollQuery.vue')
                    },
                    {
                        path: 'user/userScoreImport',
                        name: 'userScoreImport',
                        meta: { title: '证书导入', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "user" */ '../views/user/UserResultScoreImport.vue')
                    },
                    {
                        path: 'user/classlist',
                        name: 'classlist',
                        meta: { title: '班级管理', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "user" */ '../views/course/ClassQuery.vue')
                    },
                    {
                        path: 'export/task/query',
                        name: 'ExportTaskQuery',
                        meta: { title: '下载任务', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/user/ExportTaskQuery.vue')
                    },
                    // 学员管理考试报名
                    {
                        path: 'enroll/query',
                        name: 'enrollquery',
                        meta: { title: '考试报名', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/enroll/EnrollQuery.vue')
                    },
                    // 学员管理考试报名
                    {
                        path: 'enroll/tmpl',
                        name: 'enrolltmpl',
                        meta: { title: '报名资料模板', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/enroll/EnrollTmplQuery.vue')
                    },
                    {
                        path: 'learn/query',
                        name: 'learnquery',
                        meta: { title: '继续教育', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/enroll/LearnQuery.vue')
                    },
                    // 知识库
                    {
                        path: 'course/coursevideo',
                        name: 'coursevideo',
                        meta: { title: '课程视频', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/course/VideoStockQuery.vue')
                    },
                    {
                        path: 'course/courseaudio',
                        name: 'courseaudio',
                        meta: { title: '课程音频', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/course/AudioStockQuery.vue')
                    },
                    {
                        path: 'course/coursearticle',
                        name: 'coursearticle',
                        meta: { title: '课程图文', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/course/ArticleStockQuery.vue')
                    },
                    {
                        path: 'course/gcQuery',
                        name: 'GcQuery',
                        meta: { title: '课程视频', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/course/GcQuery.vue')
                    },
                    {
                        path: 'course/teacherlist',
                        name: 'teacherlist',
                        meta: { title: '教师管理', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "course" */ '../views/course/TeacherQuery.vue')
                    },
                    {
                        path: 'course/courselist',
                        name: 'courselist',
                        meta: { title: '课程管理', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "course" */ '../views/course/CourseQuery.vue')
                    },
                    {
                        path: 'course/courselist0406',
                        name: 'courselist',
                        meta: { title: '课程管理', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "course" */ '../views/course/CourseQuery0406BAK.vue')
                    },
                    //考试试题管理
                    {
                        path: 'exam/examsrule',
                        name: 'examsrule',
                        meta: { title: '试题规则', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "exam" */ '../views/course/ExamPaperRuleQuery.vue')
                    },
                    {
                        path: 'exam/examstock',
                        name: 'examstock',
                        meta: { title: '试题库管理', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "exam" */ '../views/course/ExamItemStockQuery.vue')
                    },
                    {
                        path: 'exam/highfreq',
                        name: 'HighfreqPaperQuery',
                        meta: { title: '专项练习题库管理', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "exam" */ '../views/course/HighfreqPaperQuery.vue')
                    },
                    //工种管理
                    {
                        path: 'gnvq/gnvqcategorylist',
                        name: 'gnvqcategorylist',
                        meta: { title: '工种类别', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "gnvq" */ '../views/gnvq/CategoryQuery.vue')
                    },
                    {
                        path: 'gnvq/gnvqlist',
                        name: 'gnvqlist',
                        meta: { title: '工种管理', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "gnvq" */ '../views/gnvq/GnvqInfoQuery.vue')
                    },
                    //运营管理
                    //资讯类别
                    {
                        path: 'cms/categorylist',
                        name: 'categorylist',
                        meta: { title: '资讯类别', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/cms/CategoryQuery.vue')
                    },
                    //资讯管理
                    {
                        path: 'cms/articlelist',
                        name: 'articlelist',
                        meta: { title: '资讯管理', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/cms/ArticleQuery.vue')
                    },
                    // 系统管理
                    {
                        path: 'system/org',
                        name: 'orglist',
                        meta: { title: '培训机构', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/system/OrgQuery.vue')
                    },
                    {
                        path: 'system/orgcus',
                        name: 'orgcus',
                        meta: { title: '培训机构', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/system/OrgCustomQuery.vue')
                    },
                    {
                        path: 'system/adminuser',
                        name: 'adminuser',
                        meta: { title: '管理员用户', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/system/AdminQuery.vue')
                    },
                    {
                        path: 'system/adminorguser',
                        name: 'adminorguser',
                        meta: { title: '管理员用户', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/system/AdminOrgQuery.vue')
                    },
                    {
                        path: 'system/dept',
                        name: 'dept',
                        meta: { title: '部门管理', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/system/DeptQuery.vue')
                    },
                    {
                        path: 'system/staff',
                        name: 'staff',
                        meta: { title: '员工管理', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/system/StaffQuery.vue')
                    },
                    {
                        path: 'system/role',
                        name: 'rolelist',
                        meta: { title: '角色管理', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/system/RoleQuery.vue')
                    },
                    {
                        path: 'system/menu',
                        name: 'menulist',
                        meta: { title: '权限管理', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/system/MenuQuery.vue')
                    },
                    {
                        path: 'system/dict',
                        name: 'dictquery',
                        meta: { title: '数据字典', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/system/DictQuery.vue')
                    },
                    // 电子书
                    {
                        path: 'system/ebook',
                        name: 'ebook',
                        meta: { title: '电子书', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/system/ebook/EbookQuery.vue')
                    },
                    {
                        path: 'system/logs',
                        name: 'logslist',
                        meta: { title: '日志查询', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/system/LogQuery.vue')
                    },
                    {
                        path: 'system/certtmpl',
                        name: 'certtmpl',
                        meta: { title: '证书模板管理', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/system/CertTmplQuery.vue')
                    },
                    // 财务管理
                    {
                        path: 'report/invoice',
                        name: 'invoice',
                        meta: { title: '发票管理', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/account/InvoiceQuery.vue')
                    },
                    {
                        path: 'report/accountSum',
                        name: 'accountSum',
                        meta: { title: '对账汇总', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/account/AccountSum.vue')
                    },
                    {
                        path: 'report/accountDtl',
                        name: 'accountDtl',
                        meta: { title: '对账明细', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/account/AccountDtl.vue')
                    },
                    {
                        path: 'course/order',
                        name: 'CourseOrderQuery',
                        meta: { title: '课程订单', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/account/CourseOrderQuery.vue')
                    },
                    {
                        path: 'pay/order',
                        name: 'PayOrderQuery',
                        meta: { title: '课程订单', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/account/PayOrderQuery.vue')
                    },
                    {
                        path: 'refund/order',
                        name: 'refundQuery',
                        meta: { title: '报名退款订单', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/account/RefundOrderQuery.vue')
                    },
                    // 教务管理
                    {
                        path: 'edumgr/bookpost',
                        name: 'bookpost',
                        meta: {
                            title: '教材邮寄管理',
                            icon: 'md-notifications'
                        },
                        component: () => import(/* webpackChunkName: "report" */ '../views/edumgr/BookPostQuery.vue'),
                    },
                    {
                        path: 'asq/query',
                        name: 'asq',
                        meta: {
                            title: '调查问卷',
                            icon: 'md-notifications'
                        },
                        component: () => import(/* webpackChunkName: "report" */ '../views/edumgr/AsqQuery.vue'),
                    },
                    {
                        path: 'edumgr/query',
                        name: 'edumgrquery',
                        meta: { title: '学习管理', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/edumgr/EduMgrQuery.vue')
                    },
                    {
                        path: 'edumgr/online',
                        name: 'edumgronlinequery',
                        meta: { title: '在线考试', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/edumgr/OnlineMgrQuery.vue')
                    },
                    {
                        path: 'edumgr/entrollStat',
                        name: 'entrollStat',
                        meta: {
                            title: '报名统计',
                            icon: 'md-menu'
                        },
                        component: () => import(/* webpackChunkName: "gnvq" */ '../views/edumgr/EntrollStat.vue')
                    },
                    {
                        path: 'train/plan',
                        name: 'plan',
                        meta: {
                            title: '培训计划',
                            icon: 'md-menu'
                        },
                        component: () => import(/* webpackChunkName: "gnvq" */ '../views/edumgr/TrainPlanQuery.vue')
                    },
                    // 营销管理
                    {
                        path: 'sale/apply',
                        name: 'apply',
                        meta: { title: '申请管理', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/sale/ApplyQuery.vue')
                    },
                    {
                        path: 'sale/acount',
                        name: 'acount',
                        meta: {
                            title: '账户管理',
                            icon: 'md-menu'
                        },
                        component: () => import(/* webpackChunkName: "gnvq" */ '../views/sale/AccountQuery.vue')
                    },
                    // 运动处方、专家库
                    {
                        path: 'fitness/dict',
                        name: 'fitnessdict',
                        meta: { title: '数据字典管理', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/fitness/dict/DictQuery.vue')
                    },
                    {
                        path: 'fitness/category',
                        name: 'fitnesscategory',
                        meta: { title: '处方分类', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/fitness/dict/CategoryQuery.vue')
                    },
                    {
                        path: 'fitness/items',
                        name: 'fitnessitems',
                        meta: { title: '运动项目', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/fitness/dict/ItemsQuery.vue')
                    },
                    {
                        path: 'fitness/expert',
                        name: 'fitnessexpert',
                        meta: { title: '专家列表', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/fitness/expert/ExpertQuery.vue')
                    },
                    {
                        path: 'fitness/recipe',
                        name: 'RecipeQuery',
                        meta: { title: '处方管理', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/fitness/recipe/RecipeQuery.vue')
                    },
                    {
                        path: 'fitness/scale',
                        name: 'ScaleQuery',
                        meta: { title: '测评管理', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/fitness/scale/ScaleQuery.vue')
                    },
                    // 租户
                    {
                        path: 'tenant/website/conf',
                        name: 'websiteconf',
                        meta: { title: 'PC网站设置', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/tenant/WebsiteConfig.vue')
                    },
                    {
                        path: 'tenant/miniapp/conf',
                        name: 'miniappconf',
                        meta: { title: '小程序设置', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/tenant/MiniappConfig.vue')
                    },
                    {
                        path: 'tenant/company/conf',
                        name: 'companyconf',
                        meta: { title: '机构信息设置', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "system" */ '../views/tenant/CompanyConfig.vue')
                    },
                    // 小程序管理
                    {
                        path: 'miniapp/devmgr',
                        name: 'minidevmgrquery',
                        meta: { title: '小程序开发管理', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "miniapp" */ '../views/miniapp/MiniappDevMgr.vue')
                    },
                    {
                        path: 'miniapp/query',
                        name: 'miniquery',
                        meta: { title: '小程序管理', noCache: true, auth: true },
                        component: () => import(/* webpackChunkName: "miniapp" */ '../views/miniapp/MiniappQuery.vue')
                    },
                    // 课程交易管理
                    {
                        path: 'shop/course/query',
                        name: 'shop',
                        meta: {
                            title: '课程市场',
                            icon: 'md-notifications'
                        },
                        component: () => import(/* webpackChunkName: "report" */ '../views/shop/CourseShopQuery.vue'),
                    },
                    {
                        path: 'shop/course/myorder',
                        name: 'myorder',
                        meta: {
                            title: '课程市场',
                            icon: 'md-notifications'
                        },
                        component: () => import(/* webpackChunkName: "report" */ '../views/shop/MyCourseOrderQuery.vue'),
                    },
                    {
                        path: 'shop/course/order',
                        name: 'order',
                        meta: {
                            title: '课程市场',
                            icon: 'md-notifications'
                        },
                        component: () => import(/* webpackChunkName: "report" */ '../views/shop/CourseOrderQuery.vue'),
                    },
                ]
            },
        ]
    },
    {
        path: '/personal',
        component: Layout,
        redirect: 'noRedirect',
        name: 'personal',
        meta: {
            title: '个人中心',
            icon: 'icon'
        },
        children: [
            {
                path: 'info',
                name: 'personalinfo',
                meta: { title: '个人中心', noCache: true, auth: true },
                component: () => import(/* webpackChunkName: "personal" */ '../views/personal/index.vue')
            },
        ]
    },
    {
        path: '/miniappfastreg',
        name: 'FastRegH5',
        meta: {
            title: 'FastRegH5',
        },
        component: () => import(/* webpackChunkName: "miniapph5" */ '../views/miniapp/FastRegH5.vue')
    },
    {
        path: '/error',
        component: Layout,
        redirect: 'noRedirect',
        name: 'error',
        meta: {
            title: '错误提示',
            icon: '404'
        },
        children: [
            {
                path: '401',
                component: () => import('@/views/error-page/401'),
                name: 'Page401',
                meta: { title: '401', noCache: true }
            },
            {
                path: '404',
                component: () => import('@/views/error-page/404'),
                name: 'Page404',
                meta: { title: '404', noCache: true }
            }
        ]
    },
    {
        path: '/demo',
        component: Layout,
        redirect: 'noRedirect',
        name: 'error',
        meta: {
            title: '错误提示',
            icon: '404'
        },
        children: [
            {
                path: 'query',
                component: () => import('../views/demo/DemoQuery'),
                name: 'Page401',
                meta: { title: '401', noCache: true }
            },
            // 临时阅读界面
            {
                path: 'tempread',
                name: 'tempread',
                component: () => import('../views/demo/TempRead.vue')
            },
        ]
    },
    //临时测试demo--sun
    // {
    //     path: '/tempdemo',
    //     name: 'tempdemo',
    //     meta: {
    //         title: '临时测试demo',
    //     },
    //     component: () => import('../views/demo/TempDemo'),
    // },
];
// export const asyncRoutes = [];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    console.log(to.fullPath)
    if (to.meta && to.meta.auth) {
        // 登录后才可访问的路由验证是否已经登录
        if (!appSession.hasLogin()) {
            // that.$Message.success({
            //     content: '您未登录，请登录后操作',
            //     top: 400,
            //     duration: 3
            // });
            next('/')
            return;
        }
    }
    next()
    // let token = appSession.getCookieAccessToken();
    // if (!token && to.path !== '/') {
    //     next({
    //         path: '/',
    //         query: { redirect: to.fullPath }
    //     })
    // } else {
    //     if (to.meta && to.meta.title) document.title = '[中医科研病例采集系统] - ' + to.meta.title;
    //     next()
    // }
});

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
// export function resetRouter() {
//     const newRouter = createRouter()
//     router.matcher = newRouter.matcher // reset router
// }
export default router;
