const state = {
    btnPermissions: [],
    menuList: [],
    activeIndex: 0
};

const getters = {
    // 两个参数
    checkHas: function(state, val) {
        return state.btnPermissions.includes(val)
    }
};
const mutations = {
    setPermissions: (state, list) => {
        state.btnPermissions = list
    },
    setMenuList: (state, menuList) => {
        state.menuList = menuList
    },
    setActiveIndex: (state, index) => {
        state.activeIndex = index
    },
};
const actions = {
    setPermissions({ commit }, val) {
        commit('setPermissions', val)
    },
    setMenuList({ commit }, val) {
        commit('setMenuList', val)
    },
    setActiveIndex({ commit }, val) {
        commit('setActiveIndex', val)
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
