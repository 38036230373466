<template>
    <div :class="classObj" class="app-wrapper">
        <sidebar class="sidebar-container"/>
        <div :class="{hasTagsView:needTagsView}" class="main-container">
            <app-main/>
        </div>
    </div>
</template>

<script>
    import { AppMain, Sidebar } from './components'
    import ResizeMixin from './mixin/ResizeHandler'
    import { mapState } from 'vuex'
    export default {
        name: 'Layout',
        components: {
            AppMain,
            Sidebar,
        },
        mixins: [ResizeMixin],
        computed: {
            ...mapState({
                sidebar: state => state.app.sidebar,
                device: state => state.app.device,
                showSettings: state => state.settings.showSettings,
                needTagsView: state => state.settings.tagsView,
                fixedHeader: state => state.settings.fixedHeader
            }),
            classObj() {
                return {
                    hideSidebar: !this.sidebar.opened,
                    openSidebar: this.sidebar.opened,
                    withoutAnimation: this.sidebar.withoutAnimation,
                    mobile: this.device === 'mobile'
                }
            }
        },
        methods: {
            handleClickOutside() {
                this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../styles/mixin.scss";
    @import "../styles/variables.scss";
    .app-wrapper {
        @include clearfix;
        position: relative;
        height: 100%;
        width: 100%;

        &.mobile.openSidebar {
            position: fixed;
            top: 0;
        }
    }
</style>
