<template>
    <el-submenu v-if="menu.children && menu.children.length >= 1" :index="'/app' + menu.path">
        <template slot="title">
            <i v-if="menu.meta.icon" :class="menu.meta.icon"></i>
            <i v-else  class="el-icon-menu"></i>
            <span slot="title">{{ menu.meta.title }}</span>
        </template>
        <MenuTree v-for="item in menu.children" :key="item.id" :menu="item"></MenuTree>
    </el-submenu>
    <el-menu-item v-else :index="'/app' + menu.path">
        <i v-if="menu.meta.icon" :class="menu.meta.icon"></i>
        <i v-else  class="el-icon-menu"></i>
        <span slot="title">{{ menu.meta.title }}</span>
    </el-menu-item>
</template>

<script>
export default {
    name: 'MenuTree',
    props: {
        menu: {
            type: Object,
            required: true
        }
    },
    methods: {
        handleRoute(menu) {
            // 如果是嵌套页面，转换成iframe的path
            // let path = getIFramePath(menu.url)
            // if(!path) {
            //   path = menu.url
            // }
            // 通过菜单URL跳转至指定路由
            this.$router.push("/app" + menu.path)
        }
    }
}
</script>

<style scoped lang="scss">
// .el-submenu, .el-menu-item {
//   background-color: #3c4b5a44;
// }
</style>
