<template>
    <div style="display: flex; flex-direction: row;">
    <logo :collapse="isCollapse"/>
    <div class="navbar" style="flex: 1">
        <hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar"/>

        <!--        <breadcrumb id="breadcrumb-container" class="breadcrumb-container"/>-->
        <div class="left-menu">
<!--            <div class="head-title">-->
<!--                <span class="title">-->
<!--                    皓翰在线培训管理平台-->
<!--                </span>-->
<!--            </div>-->
            <el-menu
                :default-active="activeIndex"
                :router="false"
                class="el-menu-demo"
                mode="horizontal"
                @select="handleSelect"
                background-color="#2685F6"
                text-color="#fff"
                active-text-color="#fff">
                <el-menu-item index="/dashboard">
                    <template slot="title">
                        <i class="el-icon-s-home"></i>
                        <span>首页</span>
                    </template>
                </el-menu-item>
                <el-menu-item v-for="(item, idx) in menuList" :index="idx + ''" :key="idx">
                    <template slot="title">
                        <i v-if="item.meta.icon" :class="item.meta.icon"></i>
                        <i v-else class="el-icon-s-grid"></i>
                        <span>{{ item.meta.title }}</span>
                    </template>
                </el-menu-item>
<!--                <el-menu-item index="/app/enroll/query">-->
<!--                    <template slot="title">-->
<!--                        <i class="el-icon-s-grid"></i>-->
<!--                        <span>菜单入口</span>-->
<!--                    </template>-->
<!--                </el-menu-item>-->
<!--                <el-menu-item v-for="(item, idx) in moduleList" :index="'/enroll/query?moduleId=' + item.moduleId" :key="item.moduleId + idx">-->
<!--                    <i v-if="item.moduleIcon" :class="item.moduleIcon"></i>-->
<!--                    <span>{{ item.moduleName }}</span>-->
<!--                </el-menu-item>-->
                <!--                <el-menu-item v-for="(item, idx) in menuList" :index="item.path" :key="item.id + idx" v-if="subtitleIsExist(item)">-->
                <!--                    <i :class="item.meta.icon"></i>-->
                <!--                    <span>{{item.meta.title}}</span>-->
                <!--                </el-menu-item>-->
                <!--                <el-submenu v-else :index="item.id">-->
                <!--                    <template slot="title">-->
                <!--                        <i :class="item.meta.icon"></i>-->
                <!--                        <span>{{item.meta.title}}</span>-->
                <!--                    </template>-->

                <!--                    <el-menu-item v-for="(item2, idx) in item.children" :index="item2.path" :key="item2.id + idx" v-if="subtitleIsExist(item2)">{{item2.meta.title}}</el-menu-item>-->
                <!--                    <el-submenu v-else :index="item2.id">-->
                <!--                        <template slot="title">{{item2.meta.title}}</template>-->
                <!--                        <el-menu-item v-for="(item3, idx) in item2.children" :index="item3.path" :key="item3.id + idx" >{{item3.meta.title}}</el-menu-item>-->
                <!--                    </el-submenu>-->
                <!--                </el-submenu>-->
<!--                <el-menu-item index="/enroll/query">-->
<!--                    <template slot="title">-->
<!--                        <i class="el-icon-s-grid"></i>-->
<!--                        <span>考试报名</span>-->
<!--                    </template>-->
<!--                </el-menu-item>-->
<!--                <el-menu-item index="/learn/query">-->
<!--                    <template slot="title">-->
<!--                        <i class="el-icon-s-grid"></i>-->
<!--                        <span>继续教育</span>-->
<!--                    </template>-->
<!--                </el-menu-item>-->
<!--                <el-menu-item index="/edumgr/query">-->
<!--                    <template slot="title">-->
<!--                        <i class="el-icon-s-grid"></i>-->
<!--                        <span>学习统计</span>-->
<!--                    </template>-->
<!--                </el-menu-item>-->
<!--                <el-menu-item index="/curse/VideoStockQuery">-->
<!--                    <template slot="title">-->
<!--                        <i class="el-icon-s-grid"></i>-->
<!--                        <span>课程视频</span>-->
<!--                    </template>-->
<!--                </el-menu-item>-->
<!--                <el-submenu index="/system">-->
<!--                    <template slot="title">-->
<!--                        <i class="el-icon-setting"></i>-->
<!--                        <span>系统管理</span>-->
<!--                    </template>-->
<!--                    <el-menu-item index="/system/orglist">培训机构</el-menu-item>-->
<!--                    <el-menu-item index="/system/rolelist">角色管理</el-menu-item>-->
<!--                    <el-menu-item index="/system/logslist">日志查询</el-menu-item>-->
<!--                </el-submenu>-->
<!--                <el-submenu index="/report">-->
<!--                    <template slot="title">-->
<!--                        <i class="el-icon-s-grid"></i>-->
<!--                        <span>财务管理</span>-->
<!--                    </template>-->
<!--                    <el-menu-item index="/report/invoice">发票管理</el-menu-item>-->
<!--                    <el-menu-item index="/report/accountSum">对账汇总</el-menu-item>-->
<!--                    <el-menu-item index="/report/accountDtl">对账明细</el-menu-item>-->
<!--                </el-submenu>-->
            </el-menu>
        </div>
        <div class="right-menu">
            <template v-if="device!=='mobile'">
                <el-link href="/ebook/list" icon="el-icon-question" style="color: #ffffff; margin-right: 10px;" target="_blank">帮助</el-link>
                <!--                <search id="header-search" class="right-menu-item"/>-->
                <!--                <error-log class="errLog-container right-menu-item hover-effect"/>-->
                <div style="width: 40px;height: 60px; text-align: center; cursor: pointer">
                    <el-popover
                        placement="bottom"
                        title="企业邀请码"
                        trigger="hover">
                        <div style="width: 200px; height: 200px; padding: 18px 18px">
                            <img :src="qrBase64" alt="" style="width: 100%; height: 100%">
                        </div>
                        <i class="el-icon-share" slot="reference"></i>
                    </el-popover>
                </div>
                <screenfull id="screenfull" class="right-menu-item hover-effect"/>
                <p class="name"><span style="font-weight: bold">{{ this.appCookies.getCookieOSNAME() }}</span>：{{ this.appCookies.getCookieTNAME() }} </p>
                <!--                <el-tooltip content="Global Size" effect="dark" placement="bottom">-->
                <!--                    <size-select id="size-select" class="right-menu-item hover-effect"/>-->
                <!--                </el-tooltip>-->

            </template>

            <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">

                <div class="avatar-wrapper">
                    <img src="../../assets/images/avatar.png" class="user-avatar">
                    <i class="el-icon-caret-bottom"/>
                </div>
                <el-dropdown-menu slot="dropdown">
                    <router-link to="/personal/info">
                        <el-dropdown-item>个人信息</el-dropdown-item>
                    </router-link>
                    <!--                    <router-link to="/">-->
                    <!--                        <el-dropdown-item>首页</el-dropdown-item>-->
                    <!--                    </router-link>-->
                    <!--                    <a target="_blank" href="https://github.com/PanJiaChen/vue-element-admin/">-->
                    <!--                        <el-dropdown-item>Github</el-dropdown-item>-->
                    <!--                    </a>-->
                    <!--                    <a target="_blank" href="https://panjiachen.github.io/vue-element-admin-site/#/">-->
                    <!--                        <el-dropdown-item>Docs</el-dropdown-item>-->
                    <!--                    </a>-->
                    <el-dropdown-item divided @click.native="logout">
                        <span style="display:block;">退出</span>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Logo from './Sidebar/Logo'
// import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
// import ErrorLog from '@/components/ErrorLog'
import Screenfull from '@/components/Screenfull'
// import SizeSelect from '@/components/SizeSelect'
// import Search from '@/components/HeaderSearch'

export default {
    components: {
        // ErrorLog,
        Screenfull,
        Hamburger,
        Logo
        // SizeSelect,
        // Search
    },
    computed: {
        ...mapState({
            menuList: state => state.btnPermissions.menuList,
            // activeIndex: state => state.btnPermissions.activeIndex
        }),
        ...mapGetters([
            'sidebar',
            'avatar',
            'device'
        ]),
        isCollapse() {
            return !this.sidebar.opened
        }
    },
    data() {
        return {
            activeIndex: '/dashboard',
            moduleList: [],
            qrBase64: ''
        };
    },
    mounted() {
        this.getMenuList();
        this.getOrgQr()
    },
    methods: {
        getMenuList: function () {
            this.apis.get('system/admin/getMenu?userId=' + this.appCookies.getCookieUID())
                .then((res) => {
                    if (res.code === 1) {
                        // this.menuList = res.data.menuList
                        this.$store.dispatch('btnPermissions/setMenuList', res.data.menuList)
                        this.$store.dispatch('btnPermissions/setPermissions', res.data.permissions)
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        subtitleIsExist: function (item) {
            if (item.children && item.children.length > 0) {
                return false
            } else {
                return true
            }
        },
        getModuleList: function () {
            let url = 'system/menu/module/getListByUser?userId=' + this.appCookies.getCookieUID();
            this.apis.get(url)
                .then((res) => {
                    if (res.code === 1) {
                        this.moduleList = res.data;
                        // this.$store.dispatch('btnPermissions/setPermissions', res.data.permissions)
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        getOrgQr: function () {
            let url = 'system/org/shareQr?orgId=' + this.appCookies.getCookieTOID() + '&companyId=' + this.appCookies.getCookieOID();
            this.apis.get(url)
                .then((res) => {
                    if (res.code === 1) {
                        this.qrBase64 = res.data;
                        // this.$store.dispatch('btnPermissions/setPermissions', res.data.permissions)
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        handleSelect(key, keyPath) {
            // console.log(key, keyPath);
            if (key === '/dashboard') {
                this.$router.push('/dashboard');
            } else {
                this.$store.dispatch('btnPermissions/setActiveIndex', key)
                this.$router.push('/app/dashboard?n='+key);
            }
        },
        toggleSideBar() {
            this.$store.dispatch('app/toggleSideBar')
        },
        async logout() {
            // await this.$store.dispatch('user/logout');
            // this.$router.push(`/login?redirect=${this.$route.fullPath}`);
            this.appCookies.clearCookie();
            this.$router.push('/');
        }
    }
}
</script>

<style lang="scss" scoped>
.navbar {
    height: 61px;
    overflow: hidden;
    position: relative;
    /*background: #fff;*/
    background: #2685F6;
    box-shadow: 0 1px 4px rgba(0, 21, 41, .08);

    display: flex;
    flex-direction: row;
    //justify-content: space-between;
    align-items: center;
    .left-menu {
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex: 1;
        .head-title {
            .title {
                height: 100%;
                line-height: 60px;
                font-size: 25px;
                font-weight: bold;
                padding: 0 40px;
                color: #fff;
            }
        }
        ::v-deep {
            .el-menu-item {
                padding: 0 10px;
            }

            .el-menu--horizontal {
                border-bottom: none;
            }

            .el-menu-item {
                font-size: 18px;
            }

            .el-menu-item i {
                color: #fff;
            }

            .el-submenu .el-submenu__title {
                font-size: 18px !important;

                i {
                    color: #ffffff;
                }
            }

            .el-submenu__title i {
                color: #ffffff !important;
            }
        }
    }

    .hamburger-container {
        line-height: 58px;
        height: 100%;
        float: left;
        cursor: pointer;
        transition: background .3s;
        -webkit-tap-highlight-color: transparent;
        &:hover {
            background: rgba(0, 0, 0, .025)
        }
    }

    .breadcrumb-container {
        float: left;
    }

    .errLog-container {
        display: inline-block;
        vertical-align: top;
    }

    .right-menu {
        background: #2685F6;
        /*float: right;*/
        color: #fff;
        height: 100%;
        line-height: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;

        &:focus {
            outline: none;
        }

        .right-menu-item {
            display: inline-block;
            padding: 0 8px;
            height: 100%;
            font-size: 18px;
            color: #fff;
            vertical-align: text-bottom;

            &.hover-effect {
                cursor: pointer;
                transition: background .3s;

                &:hover {
                    background: rgba(0, 0, 0, .025)
                }
            }
        }

        .name {
            font-size: 14px;
            line-height: 60px;
        }

        .avatar-container {
            margin-right: 30px;

            .avatar-wrapper {
                margin-top: 5px;
                position: relative;

                .user-avatar {
                    cursor: pointer;
                    width: 40px;
                    height: 40px;
                    border-radius: 10px;
                }

                .el-icon-caret-bottom {
                    cursor: pointer;
                    position: absolute;
                    right: -20px;
                    top: 25px;
                    font-size: 12px;
                }
            }
        }
    }
}
</style>
