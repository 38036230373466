import 'babel-polyfill';
import Vue from 'vue';
// import Cookies from 'js-cookie'
import appSession from '@js/session.sg'
import ElementUI from 'element-ui'
import './styles/element-variables.scss'
import './styles/index.scss' // global css
import './views/user/dragDialog'
import App from './App.vue';
import router from './router';
import APlayer from '@moefe/vue-aplayer';

import store from './store';

import './assets/fonts/iconfont.css'
import './icons' // icon
// import './permission' // permission control
import './utils/error-log' // error log

import * as filters from './filters' // global filters

import '@js/axios.config.js'
import '@js/http.js'
import '@js/PrivRange.js'
// import Avue from '@smallwei/avue'
// import '@smallwei/avue/lib/index.css'
// import AvueFormDesign from '@sscfaith/avue-form-design'

import VueCodeMirror from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import Contextmenu from "vue-contextmenujs"

import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'

import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
Vue.use(mavonEditor)

Vue.use(VueVideoPlayer);
Vue.use(ElementUI, {
    size: 'small', // set element-ui default size
});

Vue.use(Contextmenu);

Vue.use(APlayer, {
    defaultCover: 'https://github.com/u3u.png',
    productionTip: true,
  });
Vue.use(VueCodeMirror);
Vue.prototype.$CodeMirror = VueCodeMirror;
// Vue.use(Avue)
// Vue.use(AvueFormDesign);
//加载过滤器
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
})

const hasPermission = permission => {
    return store.getters.btnPermissions.includes(permission)
};
Vue.prototype.hasPerm = hasPermission;

Vue.prototype.appCookies = appSession;
Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
