// import Vue from 'vue'
// import Cookies from 'js-cookie'
// let expires_days = 1;
import Vue from "vue";
import appSession from '@js/session.sg'
const privRange = {
    // ALL = 90; // 全部数据
    // ORGALL: 80, // 本机构数据
    // ORG: 70, // 本机构数据
    // DEPT: 60, // 本部门数据
    // CLASS: 50, // 本班级数据
    // SELF: 10 // 个人数据
    ALL() {
        return 90; // 全部数据
    },
    ORGALL() {
        return 80; // 本机构数据
    },
    ORG() {
        return 70; // 本机构数据
    },
    DEPT() {
        return 60; // 本部门数据
    },
    CLASS() {
        return 50; // 本班级数据
    },
    havePriv() {
        // alert(appSession.getCookieSOID())
        if (appSession.getCookieSOID() === 0) {
            if (appSession.getRangeCode() > this.ORG()) {
                return true;
            }
        }
        return false;
    },
}

// 导出
Vue.prototype.privRange = privRange;
