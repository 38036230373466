// import Vue from 'vue'
// import Cookies from 'js-cookie'
// let expires_days = 1;
const appSession = {
    setCookieUser(data) {
        // sessionStorage.setItem('user_id', data.userId);
        // sessionStorage.setItem('true_name', data.trueName);
        // sessionStorage.setItem('user_name', data.userName);
        // sessionStorage.setItem('user_type', data.userType);
        // sessionStorage.setItem('org_id', data.orgId);
        // sessionStorage.setItem('org_name', data.orgName);
        // Cookies.set('admininfo', data, { expires: expires_days });
        sessionStorage.setItem('ORGinfo', JSON.stringify(data));
    },
    setCookieToten(data) {
        // Cookies.set('admintoken', data, { expires: expires_days });
        sessionStorage.setItem('ORGtoken', JSON.stringify(data));
    },
    getCookieAccessToken() {
        // let token = Cookies.getJSON('admintoken');
        let token = JSON.parse(sessionStorage.getItem('ORGtoken'));
        if (token) {
            if (token['access_token']) {
                return token['access_token'];
            }
        }
        return '';
    },
    getCookieInfo(field) {
        // let info = Cookies.getJSON('admininfo');
        let info = JSON.parse(sessionStorage.getItem('ORGinfo'));
        if (info) {
            return info[field];
        }
        return ''
    },
    getCookieOpenId() {
        return this.getCookieInfo('openId');
    },
    getCookieUID() {
        return this.getCookieInfo('userId');
    },
    getCookieOID() {
        return this.getCookieInfo('orgId');
    },
    getCookieTOID() {
        return this.getCookieInfo('tenantOgrId');
    },
    getCookieSOID() {
        return this.getCookieInfo('supperOrgId');
    },
    getCookieONAME() {
        return this.getCookieInfo('orgName');
    },
    getCookieOSNAME() {
        return this.getCookieInfo('shortName');
    },
    getCookieUNAME() {
        return this.getCookieInfo('userName');
    },
    getCookieUTYPE() {
        return this.getCookieInfo('userType');
    },
    getCookieTNAME() {
        return this.getCookieInfo('trueName');
    },
    getTenantId() {
        return this.getCookieInfo('tenantId');
    },
    clearCookie() {
        // return sessionStorage.clear();
        // Cookies.remove('admintoken');
        // Cookies.remove('admininfo');
        sessionStorage.clear();
    },
    getRangeCode() {
        return this.getCookieInfo('rangeCode');
    },
    // isAmdin() {
    //     return this.getCookieInfo('isAmdin');
    // },
    hasLogin() {
        // console.log(this.getCookieAccessToken())
        if (this.getCookieAccessToken()) {
            return true;
        } else {
            return false;
        }
    }
}

// 导出
export default appSession
