<template>
    <div id="app"  @click="updateLastTime()">
        <router-view/>
    </div>
</template>
<script>
export default {
    name: 'App',
    methods: {
        updateLastTime(){
            this.$store.commit('login/SET_LASTTIME', new Date().getTime());
            console.log(this.$store.state.login.lastTime)
        }
    }
}
</script>
<style lang="less">


    #app {
        font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        /*text-align: center;*/
        //background: #f0f2f5;
    }
    /*html, body, #app {*/
    /*    height: 100%;*/
    /*    width: 100%;*/
    /*}*/

</style>
