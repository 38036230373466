<template>
<!--    <div :class="{'has-logo':showLogo}">-->
<!--        <logo v-if="showLogo" :collapse="isCollapse"/>-->
    <div>
        <el-scrollbar wrap-class="scrollbar-wrapper">
<!--            <el-menu-->
<!--                :default-active="activeMenu"-->
<!--                :collapse="isCollapse"-->
<!--                :background-color="variables.menuBg"-->
<!--                :text-color="variables.menuText"-->
<!--                :unique-opened="false"-->
<!--                :active-text-color="variables.menuActiveText"-->
<!--                :collapse-transition="false"-->
<!--                mode="vertical"-->
<!--            >-->
<!--                <sidebar-item v-for="route in permission_routes" :key="route.path" :item="route"-->
<!--                              :base-path="route.path"/>-->
<!--            </el-menu>-->
            <!-- 导航菜单 -->
            <el-menu ref="navmenu"
                     :default-active="activeMenu"
                     :text-color="'#303133'"
                     :active-text-color="'#2685F6'"
                     router
                     :collapse="isCollapse" :collapse-transition="false" :unique-opened="false" mode="vertical">
                <!-- 导航菜单树组件，动态加载菜单 -->
                <template v-if="menuList.length > 0">
                    <menu-tree v-for="item in menuList[activeIndex].children" :key="item.id" :menu="item"></menu-tree>
                </template>
            </el-menu>
        </el-scrollbar>
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'
    // import Logo from './Logo'
    import MenuTree from './MenuTree'
    import variables from '../../../styles/variables.scss'
    export default {
        components: {
            // SidebarItem,
            MenuTree,
            // Logo
        },
        data() {
            return {
                menuList2: [
                    {
                        id: 1,
                        name: '111',
                        icon: 'el-icon-setting',
                        "meta": {
                            "icon": "md-menu",
                            "title": "角色管理"
                        },
                    },
                    {
                        id: 2,
                        name: '111',
                        icon: 'el-icon-setting',
                        "meta": {
                            "icon": "md-menu",
                            "title": "角色管理"
                        },
                        children: [
                            {
                                "id": "24",
                                "name": "菜单权限",
                                "path": "/system/menu",
                                "icon": "md-menu",
                                "parentId": "20",
                                "meta": {
                                    "icon": "md-menu",
                                    "title": "菜单权限"
                                },
                                "orderNum": 24,
                                "children": []
                            }, {
                                "id": "25",
                                "name": "角色管理",
                                "path": "/system/role",
                                "icon": "md-menu",
                                "parentId": "20",
                                "meta": {
                                    "icon": "md-menu",
                                    "title": "角色管理"
                                },
                                "orderNum": 25,
                                "children": []
                            }
                        ]
                    }
                ]
            }
        },
        computed: {
            ...mapGetters([
                'permission_routes',
                'sidebar'
            ]),
            ...mapState({
                activeIndex: state => state.btnPermissions.activeIndex,
                menuList: state => state.btnPermissions.menuList
            }),
            activeMenu() {
                const route = this.$route
                const { meta, path } = route
                // if set path, the sidebar will highlight the path you set
                if (meta.activeMenu) {
                    return meta.activeMenu
                }
                return path
            },
            showLogo() {
                return this.$store.state.settings.sidebarLogo
            },
            variables() {
                return variables
            },
            isCollapse() {
                return !this.sidebar.opened
            }
        },
        mounted () {
            // this.getMenuList();
        },
        methods: {
            getMenuList: function () {
                this.apis.get('system/admin/getMenu?userId=' + this.appCookies.getCookieUID())
                    .then((res) => {
                        if (res.code === 1) {
                            // this.menuList = res.data.menuList
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            }
        }
    }
</script>
